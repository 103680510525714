import React from "react"
import styled from "styled-components"
// components
import Heading1 from "../atoms/heading_1"
import Heading2 from "../atoms/heading_2"

const components = {
  Heading1,
  Heading2
}

const StyledTitleCenter = styled.div`
  display: flex;
  justify-content: center;
  margin-bottom: 10px;
`

const Wrapper = styled.div`
  padding: 0 50px 10px;
  position: relative;

  &::after {
    background: #e7e7eb;
    bottom: 0;
    content: "";
    display: block;
    height: 3px;
    position: absolute;
    right: 0;
    width: 60%;
  }

  &::before {
    background: #67a70c;
    bottom: 0;
    content: "";
    display: block;
    height: 3px;
    left: 0;
    position: absolute;
    width: 40%;
  }
`

const TitleCenter = ({
  level,
  ...rest
}) => {
  const Component = components[`Heading${level}`]
  return (
    <StyledTitleCenter>
      <Wrapper>
        <Component {...rest} />
      </Wrapper>
    </StyledTitleCenter>
  )
}

export default TitleCenter
