import React from "react"
import styled from "styled-components"

const StyledHeading2 = styled.h2`
  align-items: ${props => props.alignItems};
  border-right: ${props => props.borderRight};
  display: ${props => props.display};
  font-size: ${props => props.fontSize};
  justify-content: ${props => props.justifyContent};
  line-height: 1.5;
  margin: 0;
  white-space: ${props => props.whiteSpace};
`

const Heading2 = ({
  heading,
  ...rest
}) => {
  return (
    <StyledHeading2 {...rest}>
      {heading}
    </StyledHeading2>
  )
}

export default Heading2
