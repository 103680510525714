import React from "react"
import styled from "styled-components"
import { GatsbyImage } from "gatsby-plugin-image"
// components
import Heading1 from "../atoms/heading_1"

const StyledTitleImage = styled.div`
  background: rgba(0,0,0,0.75);
  display: grid;
  grid-column-end: -1;
  grid-column-start: 1;
  grid-template-columns: inherit;
  grid-template-rows: inherit;
  overflow: hidden;
  position: relative;
`

const Wrapper = styled.div`
  align-items: ${props => props.alignItems};
  display: ${props => props.display};
  grid-column-end: ${props => props.gridColumnEnd};
  grid-column-start: ${props => props.gridColumnStart};
  height: ${props => props.height};
  justify-content: ${props => props.justifyContent};
  left: ${props => props.left};
  position: ${props => props.position};
  transform: ${props => props.transform};
  z-index: ${props => props.zIndex};
`

const TitleImage = ({
  image,
  alt,
  heading
}) => {
  return (
    <StyledTitleImage>
      <Wrapper
        left="50%"
        position="absolute"
        transform="translateX(-50%)"
        zIndex="-1"
      >
        <GatsbyImage
          image={image}
          alt={alt}
        />
      </Wrapper>
      <Wrapper
        alignItems="center"
        display="flex"
        gridColumnEnd="-2"
        gridColumnStart="2"
        height="250px"
        justifyContent="center"
      >
        <Heading1
          color="#ffffff"
          fontSize="xx-large"
          heading={heading}
        />
      </Wrapper>
    </StyledTitleImage>
  )
}

export default TitleImage
