import React from "react"
import { useMediaQuery } from "react-responsive"

const Table = ({
  marginBottom,
  table
}) => {
  const isMobile = useMediaQuery({ maxWidth: 767 })
  return (
    <table
      style={{
        borderCollapse: "collapse",
        marginBottom: marginBottom,
        width: "100%"
      }}
    >
      <tbody>
        {table.map((value, index) => {
          return (
            <tr
              key={index}
              style={{
                borderBottom: "1px solid #7d7d7d",
                borderTop: "1px solid #7d7d7d",
                fontSize: "15px",
                lineHeight: 1.5
              }}
            >
              <th
                style={{
                  background: "#e8ecef",
                  borderBottom: isMobile && "1px solid #7d7d7d",
                  display: isMobile && "block",
                  padding: "10px",
                  textAlign: "left",
                  width: !isMobile && "300px"
                }}
              >
                {value.header}
              </th>
              <td
                style={{
                  display: isMobile && "block",
                  padding: "10px",
                  whiteSpace: "pre-wrap"
                }}
              >
                {value.data}
              </td>
            </tr>
          )
        })}
      </tbody>
    </table>
  )
}

export default Table
