import React from "react"
import styled from "styled-components"
import { GatsbyImage } from "gatsby-plugin-image"
// components
import Heading2 from "../atoms/heading_2"
import Paragraph from "../atoms/paragraph"

const StyledCatch = styled.div`
  display: flex;
  flex-direction: ${props => props.flexDirection};
`

const Wrapper = styled.div`
  background: #ffffff;
  display: grid;
  flex: 1;
  grid-template-columns: 300px auto;
  grid-template-rows: auto;
  margin-left: ${props => props.marginLeft};
  margin-right: ${props => props.marginRight};
  margin-top: 15px;
  padding: 20px 0;
  position: relative;
`

const Catch = ({
  reverse,
  _catch
}) => {
  return (
    <StyledCatch flexDirection={reverse && "row-reverse"}>
      <GatsbyImage
        image={_catch.image}
        alt={_catch.alt}
      />
      <Wrapper
        marginLeft={!reverse && "-100px"}
        marginRight={reverse && "-100px"}
      >
        <Heading2
          alignItems="center"
          borderRight="1px solid #7d7d7d"
          display="flex"
          fontSize="large"
          justifyContent="center"
          whiteSpace="pre-wrap"
          heading={_catch.heading}
        />
        <Paragraph
          alignItems="center"
          display="flex"
          fontSize="15px"
          padding="0 20px 0 10px"
          whiteSpace="pre-wrap"
          paragraph={_catch.paragraph}
        />
      </Wrapper>
    </StyledCatch>
  )
}

export default Catch
