import React from "react"
import styled from "styled-components"
// components
import TitleCenter from "../molecules/title_center"
import Paragraph from "../atoms/paragraph"

const StyledGreeting = styled.div`
  grid-column-end: -2;
  grid-column-start: 2;
  padding: 25px 0;
`

const Greeting = () => {
  const paragraph = "マンションの大規模修繕工事から一戸建て、内装工事まで多方面にわたる分野まで対応致します。\nマルトモの成長とビジョンを通じて、お客様そして従業員が誇れる企業となるよう日々努力を積み重ねて参ります。"
  return (
    <StyledGreeting>
      <TitleCenter
        level={2}
        fontSize="20px"
        heading="ご挨拶"
      />
      <Paragraph
        display="flex"
        fontSize="15px"
        justifyContent="center"
        whiteSpace="pre-wrap"
        paragraph={paragraph}
      />
    </StyledGreeting>
  )
}

export default Greeting
