import React from "react"
import styled from "styled-components"
// components
import TitleCenter from "../molecules/title_center"
import Table from "../molecules/table"

const StyledInformation = styled.div`
  grid-column-end: -2;
  grid-column-start: 2;
  padding-bottom: ${props => props.paddingBottom};
`

const Information = ({
  heading,
  table,
  ...rest
}) => {
  return (
    <StyledInformation {...rest}>
      <TitleCenter
        level={2}
        fontSize="20px"
        heading={heading}
      />
      <Table table={table} />
    </StyledInformation>
  )
}

export default Information
