import React from "react"
import styled from "styled-components"
import { useMediaQuery } from "react-responsive"
import { getImage } from "gatsby-plugin-image"
import { graphql } from "gatsby"
// components
import Layout from "../../components/layout"
import Catch from "../../components/molecules/catch"
import TitleImage from "../../components/molecules/title_image"
import Greeting from "../../components/organisms/greeting"
import Information from "../../components/organisms/information"
import SEO from "../../components/seo"

const Wrapper = styled.div`
  display: grid;
  grid-column-end: -2;
  grid-column-start: 2;
  grid-row-gap: 10px;
`

const Company = ({
  data,
  location
}) => {
  const isDesktop = useMediaQuery({ minWidth: 960 })
  const catches = [
    {
      image: getImage(data.catch_1),
      alt: "", //
      heading: "安心して快適に過ごせる建物を",
      paragraph: "マルトモは、オーナー様・居住者様が安心して快適に過ごすことができるよう、建物の修繕工事を主な業務としています。\n修繕工事又はその後のアフターケアで責任を持ってお客様の建物をお守りします。"
    },
    {
      image: getImage(data.catch_2),
      alt: "", //
      heading: "より良い材料\nより良い塗料\nより良い施工を",
      paragraph: "仮設工事から全ての工程において、中間コストを発生させず、弊社にて責任を持って施工致します。\nそのため、より良い材料、より良い塗料、より良い工事方法を選ぶ事が可能になります。"
    },
    {
      image: getImage(data.catch_3),
      alt: "", //
      heading: "お客様の大切な資産を守り\n共に発展し続けます",
      paragraph: "わたしたちマルトモは、全社員が現場経験を持つ工事会社です。\nお客様の大切な資産である建物を、安心して快適に住めるようにすることをお約束致します。"
    }
  ]
  const table = [
    {
      header: "社名",
      data: "株式会社マルトモ"
    },
    {
      header: "代表者",
      data: "代表取締役会長　荒畑 智康\n代表取締役社長　石川 収三"
    },
    {
      header: "所在地",
      data: "本社・工事部　東京都中野区新井5-33-7"
    },
    {
      header: "TEL",
      data: "03-5380-0065 (営業部)\n03-3388-2296 (本社・工事部)"
    },
    {
      header: "FAX",
      data: "03-5380-8273 (営業部)"
    },
    {
      header: "設立年月日",
      data: "昭和58年4月15日"
    },
    {
      header: "事業内容",
      data: "修繕工事及び総合リフォーム"
    },
    {
      header: "建築業許可番号",
      data: "東京都知事 許可(般-18) 第127335号"
    },
    {
      header: "社員数",
      data: "20名"
    },
    {
      header: "取引銀行",
      data: "西武信用金庫 中野北口支店"
    }
  ]
  return (
    <Layout location={location}>
      {isDesktop
        ? <Wrapper>
            {catches.map((_catch, index) => {
              return (
                <Catch
                  key={index}
                  reverse={
                    index % 2 !== 0
                      ? true
                      : false
                  }
                  _catch={_catch}
                />
              )
            })}
          </Wrapper>
        : <TitleImage
            image={getImage(data.company)}
            alt="" //
            heading="会社概要"
          />
      }
      <Greeting />
      <Information
        paddingBottom="25px"
        heading="会社情報"
        table={table}
      />
    </Layout>
  )
}

export default Company

export const query = graphql`
  query {
    catch_1: file(relativePath: { eq: "catch_1.jpg" }) {
      childImageSharp {
        gatsbyImageData(
          layout: FIXED,
          width: 465
        )
      }
    }
    catch_2: file(relativePath: { eq: "catch_2.jpg" }) {
      childImageSharp {
        gatsbyImageData(
          layout: FIXED,
          width: 465
        )
      }
    }
    catch_3: file(relativePath: { eq: "catch_3.jpg" }) {
      childImageSharp {
        gatsbyImageData(
          layout: FIXED,
          width: 465
        )
      }
    }
    company: file(relativePath: { eq: "company.JPG" }) {
      childImageSharp {
        gatsbyImageData(
          height: 250,
          layout: FIXED
        )
      }
    }
  }
`

export const Head = ({ location }) => (
  <SEO
    title="会社概要"
    description="株式会社マルトモの会社概要ページです。"
    pathname={location.pathname}
  />
)
